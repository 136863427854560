<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.perfAppraiseList) ? detail.perfAppraiseList : []
      "
      :pagination="false"
    >
      <a-table-column title="考核年度">
        <template slot-scope="text">
          {{ text.year }}
        </template>
      </a-table-column>
      <a-table-column title="考核结果(分)">
        <template slot-scope="text">
          {{ text.result }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>