<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="Array.isArray(detail.trainList) ? detail.trainList : []"
      :pagination="false"
    >
      <a-table-column title="培训名称">
        <template slot-scope="text">
          {{ text.name }}
        </template>
      </a-table-column>
      <a-table-column title="培训日期">
        <template slot-scope="text">
          {{ text.time }}
        </template>
      </a-table-column>

      <a-table-column title="培训地点">
        <template slot-scope="text">
          {{ text.address }}
        </template>
      </a-table-column>

      <a-table-column title="服务期起始日期">
        <template slot-scope="text">
          {{ text.startTime }}
        </template>
      </a-table-column>

      <a-table-column title="服务期结束日期">
        <template slot-scope="text">
          {{ text.endTime }}
        </template>
      </a-table-column>
      
      <a-table-column title="培训机构">
        <template slot-scope="text">
          {{ text.mechanism }}
        </template>
      </a-table-column>

      <a-table-column title="培训讲师">
        <template slot-scope="text">
          {{ text.teacher }}
        </template>
      </a-table-column>


      <a-table-column title="培训结果">
        <template slot-scope="text">
          {{ text.result }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>