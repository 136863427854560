<template>
  <div style="flex: 1; height: 40vh" :id="id"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { RadarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([RadarChart, CanvasRenderer]);

export default {
  props: {
    id: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    var myChart = echarts.init(chartDom);

    this.chart = myChart;

    var option;

    option = {
      radar: {
        indicator: this.list.map((item) => {
          return {
            name: item.name,
            max: item.max,
          };
        }),
      },
      series: [
        {
          name: "综合分析",
          type: "radar",
          data: [
            {
              value: this.list.map((item) => item.value),
              name: "综合",
            },
          ],
        },
      ],
    };

    option && myChart.setOption(option);
  },

  beforeDestroy() {
    this.chart?.dispose();
  },
};
</script>