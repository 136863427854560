<template>
  <div>
    <CardHeader :icon="icon13" title="绩效分析" :showFold="false" />
    <div
      v-show="detail.perfAppraiseList && detail.perfAppraiseList.length > 0"
      id="perfor"
      style="width: 25vw; height: 180px"
    ></div>
    <div
      v-if="!detail.perfAppraiseList || detail.perfAppraiseList.length === 0"
      style="padding-top: 24px"
    >
      <a-empty />
    </div>
  </div>
</template>

<script>
import CardHeader from "../card-header.vue";

import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, LineChart, CanvasRenderer]);

export default {
  components: {
    CardHeader,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    detail: {
      handler() {
        this.setChart();
      },
      deep: true,
    },
  },

  data() {
    return {
      icon13: require("@/assets/employee/icon13.png"),
    };
  },

  mounted() {
    var chartDom = document.getElementById("perfor");
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  methods: {
    setChart() {
      if (
        Array.isArray(this.detail.perfAppraiseList) &&
        this.detail.perfAppraiseList.length > 0
      ) {
        var option;
        option = {
          color: [
            {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#1890ff",
                },
                {
                  offset: 1,
                  color: "#ffffff",
                },
              ],
            },
          ],

          grid: {
            top: "5%",
            left: "8%",
            right: "5%",
            bottom: "10%",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.detail.perfAppraiseList.map((item) => item.year),
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              data: this.detail.perfAppraiseList.map((item) =>
                parseInt(item.result)
              ),
              type: "line",
              areaStyle: {},
            },
          ],
        };

        option && this.chart?.setOption(option);
      }
    },
  },
};
</script>