<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.educationList) ? detail.educationList : []
      "
      :pagination="false"
    >
      <a-table-column title="毕业院校" data-index="school" />
      <a-table-column title="专业" data-index="major" />
      <a-table-column title="学习方式" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="education_channel"
            :dictValue="text.channelStudy"
          />
        </template>
      </a-table-column>
      <a-table-column title="就读日期" data-index="timeFrom" align="center" />
      <a-table-column title="毕业日期" data-index="timeTo" align="center" />
      <a-table-column title="毕业方式" align="center">
        <template slot-scope="text">
          <span v-if="graduationWay.length">
            {{
              graduationWay.find((item) => item.value === text.channelGraduate)
                ?.name
            }}
          </span>
        </template>
      </a-table-column>

      <a-table-column title="学历" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="degree" :dictValue="text.education" />
        </template>
      </a-table-column>

      <a-table-column title="学历证书编号">
        <template slot-scope="text">
          {{ text.educationNum }}
        </template>
      </a-table-column>

      <a-table-column title="学历附件" align="center">
        <template slot-scope="text">
          <a
            target="_blank"
            v-if="text.educationAttachment"
            :href="text.educationAttachment"
            >预览</a
          >
        </template>
      </a-table-column>

      <a-table-column title="学位" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="academic_degree"
            :dictValue="text.academicDegree"
          />
        </template>
      </a-table-column>

      <a-table-column title="学位证书编号">
        <template slot-scope="text">
          {{ text.academicDegreeNum }}
        </template>
      </a-table-column>

      <a-table-column title="学位附件" align="center">
        <template slot-scope="text">
          <a
            target="_blank"
            v-if="text.academicDegreeAttachment"
            :href="text.academicDegreeAttachment"
            >查看</a
          >
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    graduationWay: {
      type: Array,
      default: () => [],
    },
    education_channel: {
      type: Array,
      default: () => [],
    },
    degree: {
      type: Array,
      default: () => [],
    },
    academic_degree: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      type: "",
      editingIndex: -1,
      editingText: {},
      saveLoading: false,

      educationAttachment: "",
      academicDegreeAttachment: "",
    };
  },
};
</script>