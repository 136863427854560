<template>
  <div>
    <CardHeader :icon="icon1" title="重大项目经历" :showFold="false" />

    <div v-if="filteredList.length > 0">
      <div class="item" v-for="(item, index) in filteredList" :key="index">
        <div class="left">
          <img class="icon" :src="honorImage" alt="" />
          <div class="name">{{ item.name }}</div>
          <div class="money">¥{{ item.amount }}</div>
        </div>
        <div class="left desc">
          <DataDictFinder dictType="project-type" :dictValue="item.level" />·
          <DataDictFinder dictType="project-role" :dictValue="item.role" />
          <div class="left date">
            {{ item.timeFrom ? item.timeFrom : "" }} ~
            {{ item.timeTo ? item.timeTo : "" }}
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 24px">
      <a-empty />
    </div>
  </div>
</template>

<script>
import CardHeader from "../card-header.vue";

export default {
  components: {
    CardHeader,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    filteredList() {
      if (
        Array.isArray(this.detail.hisProjectList) &&
        this.detail.hisProjectList.length > 0
      ) {
        const list = [...this.detail.hisProjectList];
        list.sort((x, y) => {
          if (x.amount && y.amount) {
            if (x.amount < y.amount) {
              return 1;
            } else if (x.amount > y.amount) {
              return -1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        });
        if (list.length > 4) {
          const [x, y, z] = list;
          return [x, y, z];
        } else {
          return list;
        }
      } else {
        return [];
      }
    },
  },

  data() {
    return {
      icon1: require("@/assets/employee/icon1.png"),
      honorImage: require("@/assets/employee/honor/honor13.png"),
    };
  },
};
</script>

<style lang="less" scoped>
.item {
  margin-bottom: 14px;
  img {
    width: 12px;
    margin-right: 4px;
  }
  .date {
    margin-left: auto;
    color: #999;
  }
  .desc {
    margin-top: 8px;
    padding-left: 16px;
  }
  .name {
    font-weight: bold;
    font-size: 14px;
  }
  .money {
    margin-left: auto;
    color: #1890ff;
    font-weight: bold;
  }
}
</style>