<template>
  <div>
    <CardHeader :icon="icon12" title="任职轨迹" :showFold="false" />

    <div class="list" v-if="filteredList.length > 0">
      <div class="left">
        <div class="item" v-for="(item, index) in filteredList" :key="index">
          <div class="name">{{ item.post }}</div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="left">
        <div
          class="item"
          v-for="(item, index) in filteredList"
          :key="index + 1000"
        >
          <div>{{ getOrgName(item) }}</div>
          <div class="date">
            {{ item.effectiveTime ? item.effectiveTime : "" }}
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 24px">
      <a-empty />
    </div>
  </div>
</template>

<script>
import CardHeader from "../card-header.vue";
import organization from "@/mixins/organization";
import moment from "moment";
export default {
  mixins: [organization],

  components: {
    CardHeader,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    filteredList() {
      if (
        Array.isArray(this.detail.changeList) &&
        this.detail.changeList.length > 0
      ) {
        const list = [...this.detail.changeList];
        list.sort((x, y) => {
          if (x.effectiveTime && y.effectiveTime) {
            if (moment(x.effectiveTime).isBefore(moment(y.effectiveTime))) {
              return -1;
            } else if (
              moment(x.effectiveTime).isAfter(moment(y.effectiveTime))
            ) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        });
        if (list.length > 4) {
          const [x, y, z] = list;
          return [x, y, z];
        } else {
          return list;
        }
      } else {
        return [];
      }
    },
  },

  data() {
    return {
      icon12: require("@/assets/employee/icon12.png"),
    };
  },

  methods: {
    getOrgName(item) {
      const org = this.organizationList.find(
        (element) => element.id === item.deptTo
      );
      return org ? org.uniqueName : "";
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  margin-top: 32px;
  width: 95%;
}
.divider {
  border-bottom: 1px dashed #999;
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: -8px;
    right: -16px;
    border-top: 8px solid transparent;
    border-left: 8px solid #333;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
  }
}
.item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    font-size: 14px;
    color: #1890ff;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translateX(-50%);
      height: 12px;
      width: 12px;
      border: 1px solid #1890ff;
      background-color: #fff;
      z-index: 80;
      border-radius: 50%;
    }
  }
  .date {
    color: #999;
    margin-top: 4px;
  }
}
</style>