<template>
  <div id="chart2" style="height: 40vh"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, BarChart, CanvasRenderer]);

export default {
  mounted() {
    var chartDom = document.getElementById("chart2");
    var myChart = echarts.init(chartDom);

    this.chart = myChart;

    var option;

    option = {
      grid: {
        top: "10%",
        bottom: "10%",
        left: "3%",
        right: "1%",
      },
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
        },
        data: [
          "成功愿望",
          "权利动机",
          "亲和动机",
          "活力",
          "创新一是",
          "洞察力",
          "决断的",
          "理性的",
          "乐观的",
          "抗压的",
          "情绪稳定适应性",
        ],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: [80, 30, 10, 90, 70, 32, 11, 90, 80, 10, 11],
          type: "bar",
          itemStyle: {
            color: "#1890ff",
          },
          barWidth: 20,
        },
      ],
    };

    option && myChart.setOption(option);
  },

  beforeDestroy() {
    this.chart?.dispose();
  },
};
</script>