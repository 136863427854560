<template>
  <div class="history">
    <a-timeline mode="alternate">
      <a-timeline-item
        v-if="detail.probation && detail.probation.qualifiedTime"
      >
        <div class="card">
          <div class="header">
            <img :src="iconArrow" alt="" />
            <span class="title">实习期</span>
          </div>
          <div class="desc">
            实际试用期：{{
              detail.probation.startTime ? detail.probation.startTime : "--"
            }}
            -
            {{
              detail.probation.endTimeActual
                ? detail.probation.endTimeActual
                : "--"
            }}
          </div>
          <div class="desc">转正日期：{{ detail.probation.qualifiedTime }}</div>
        </div>
      </a-timeline-item>
      <template
        v-if="Array.isArray(detail.changeList) && detail.changeList.length > 0"
      >
        <a-timeline-item
          color="green"
          v-for="item in detail.changeList"
          :key="item.id"
        >
          <div class="card">
            <div class="header">
              <img :src="iconArrow" alt="" />
              <span class="title">人员异动</span>
            </div>
            <div class="desc">
              异动类型：{{
                item.type
                  ? changeType.find((element) => element.value === item.type)
                      .name
                  : "--"
              }}
            </div>
            <div class="desc">
              拟调出部门：{{
                item.deptFrom
                  ? organizationList.find(
                      (element) => element.id === item.deptFrom
                    ).uniqueName
                  : "--"
              }}
            </div>
            <div class="desc">
              拟调入部门：{{
                item.deptFrom
                  ? organizationList.find(
                      (element) => element.id === item.deptTo
                    ).uniqueName
                  : "--"
              }}
            </div>
            <div class="desc">
              异动日期：{{ item.changeTime ? item.changeTime : "--" }}
            </div>
          </div>
        </a-timeline-item>
      </template>
      <div v-else>
        <a-empty />
      </div>
    </a-timeline>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import { mapGetters } from "vuex";
export default {
  mixins: [organization],
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      iconArrow: require("@/assets/employee/icon-arrow.png"),
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    lists() {
      return this.findDataDict("talentMap");
    },

    // 异动类型
    changeType() {
      return (
        this.lists.find((item) => item.value === "changeType").children || []
      );
    },
  },
};
</script>

<style lang="less" scoped>
.history {
  padding: 48px 120px;
}
.card {
  background: rgba(24, 144, 255, 0.1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    img {
      margin-right: 8px;
    }
    .title {
      font-weight: bold;
    }
  }

  .desc {
    padding: 4px 0;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>