<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.rewardPenaltyList) ? detail.rewardPenaltyList : []
      "
      :pagination="false"
    >
      <a-table-column title="惩罚类型">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.penalty"
            :dictValue="text.rewardOrPenalty"
          />
        </template>
      </a-table-column>
      <a-table-column title="惩罚措施">
        <template slot-scope="text">
          {{ text.content }}
        </template>
      </a-table-column>
      <a-table-column title="惩罚日期">
        <template slot-scope="text">
          {{ text.time }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>