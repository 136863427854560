<template>
  <div>
    <CardHeader :icon="iconBaseInfo" title="基本信息" :showFold="false" />

    <div class="flex">
      <a v-if="detail.header" :href="detail.header" target="_blank">
        <img :src="detail.header" alt="" class="avatar" />
      </a>
      <div class="info">
        <div class="name">{{ detail.name }}</div>
        <div
          class="left position"
          v-if="detail.position && detail.position.belongDept"
        >
          <span>{{
            organizationList.find(
              (item) => item.id === detail.position.belongDept
            ).uniqueName
          }}</span>
          <span>/{{ detail.position.position }}</span>
        </div>

        <div class="description">
          <div class="left">
            <span class="label">入职时间：</span>
            <span>{{ detail.entryDate ? detail.entryDate : "--" }}</span>
          </div>
          <div class="left">
            <span class="label">手机号码：</span>
            <span>{{ detail.mobile ? detail.mobile : "--" }}</span>
          </div>
          <div class="left">
            <span class="label">邮箱：</span>
            <span>{{ detail.email ? detail.email : "--" }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeader from "../card-header.vue";
import organization from "@/mixins/organization";
export default {
  mixins: [organization],
  components: {
    CardHeader,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      iconBaseInfo: require("@/assets/employee/icon1.png"),
    };
  },
};
</script>

<style lang='less' scoped>
.avatar {
  height: 180px;
  margin-right: 24px;
  border-radius: 4px;
}
.info {
  display: flex;
  flex-direction: column;
}
.name {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 8px;
}
.position {
  font-weight: bold;
  font-size: 14px;
  color: #1890ff;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #999;
  margin-top: auto;
}

.label {
  width: 5em;
  text-align: justify-all;
  text-align-last: justify;
}
</style>
