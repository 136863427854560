<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.inventoryList) ? detail.inventoryList : []
      "
      :pagination="false"
    >
      <a-table-column title="人才盘点日期">
        <template slot-scope="text">
          {{ text.inventoryDate }}
        </template>
      </a-table-column>
      <a-table-column title="人才盘点结果">
        <template slot-scope="text">
          {{ text.inventoryResult }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>