<template>
  <div class="left" style="margin-bottom: 12px">
    <a-button v-if="detail.updateCount == null || detail.updateCount < detail.updateMax" size="small" style="position: absolute;right: 20px;top: 20px;" @click="goPage(
            detail.id? '/human-resources/map/employee/edit?id=' + detail.id
              : ''
          )">编辑个人信息</a-button>
    <a v-if="detail.header" :href="detail.header" target="_blank">
      <img :src="detail.header" alt="" class="avatar" />
    </a>
    <div>
      <div class="left" style="margin-bottom: 8px">
        <div class="name">{{ detail.name }}</div>
        <Tags :detail="detail" />
      </div>
      <div class="left description">
        <div class="left" v-if="detail.position && detail.position.belongDept">
          <img :src="iconUser" alt="" />
          <span>{{
            organizationList.find(
              (item) => item.id === detail.position.belongDept
            ).uniqueName
          }}</span>/
          <DataDictFinder dictType="talentMap.talentMap_post" :dictValue="detail.position.position" v-if="detail.position && detail.position.position" />
        </div>
        <div class="left" v-if="detail.mobile">
          <img :src="iconMobile" alt="" />
          <span>手机号码：</span>
          <span>{{ detail.mobile }}</span>
        </div>
        <div class="left" v-if="detail.phone">
          <img :src="iconCall" alt="" />
          <span>{{ detail.phone }}</span>
        </div>
        <div class="left" v-if="detail.email">
          <img :src="iconCall" alt="" />
          <span>邮箱：</span>
          <span>{{ detail.email }}</span>
        </div>

        <div class="left" v-if="detail.type">
          <span>员工类别：</span>
          <span> <DataDictFinder dictType="talentMap.employeeType" :dictValue="detail.type" /></span>
        </div>
        <div class="left" v-if="detail.status">
          <span>员工状态：</span>
          <span><DataDictFinder dictType="employeeStatus" :dictValue="detail.status"/></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import Tags from "./tags.vue";
export default {
  mixins: [organization],
  components: {
    Tags,
  },
  props: {
    detail: {
      type: Object,
    },
  },

  data() {
    return {
      iconUser: require("@/assets/employee/icon-user.png"),
      iconMobile: require("@/assets/employee/icon-mobile.png"),
      iconCall: require("@/assets/employee/icon-call.png"),
    };
  },
  methods:{
    goPage(path) {
      this.$router.push(path);
    },
  }
};
</script>

<style lang="less" scoped>
.avatar {
  height: 64px;
  width: 64px;
  border-radius: 8px;
  margin-right: 16px;
  object-fit: cover;
}

.name {
  font-size: 18px;
  font-weight: bold;
}

.description {
  font-size: 13px;
  opacity: 0.8;

  .left {
    margin-right: 24px;

    img {
      height: 14px;
      margin-right: 8px;
    }
  }
}
</style>