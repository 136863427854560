<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.relativeList) ? detail.relativeList : []
      "
      :pagination="false"
    >
      <a-table-column title="关系人姓名">
        <template slot-scope="text">
          {{ text.name }}
        </template>
      </a-table-column>
      <a-table-column title="人员类型">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.personType"
            :dictValue="text.typePerson"
          />
        </template>
      </a-table-column>
      <a-table-column title="关系类型">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.relationshipType"
            :dictValue="text.type"
          />
        </template>
      </a-table-column>

      <a-table-column title="手机号">
        <template slot-scope="text">
          {{ text.mobile }}
        </template>
      </a-table-column>
      <a-table-column title="工作单位">
        <template slot-scope="text">
          {{ text.workUnit }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>