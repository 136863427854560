var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[(_vm.detail.partyMember === 'member')?_c('img',{staticClass:"party",attrs:{"src":_vm.party1,"alt":""}}):_vm._e(),(_vm.detail.partyMember === 'modelMember')?_c('img',{staticClass:"party",attrs:{"src":_vm.party2,"alt":""}}):_vm._e(),(_vm.detail.partyMember === 'starModelMember')?_c('img',{staticClass:"party",attrs:{"src":_vm.party3,"alt":""}}):_vm._e(),(_vm.detail.honorFlag === 'excellentStaff')?_c('img',{staticClass:"party",attrs:{"src":_vm.honor9,"alt":""}}):_vm._e(),(_vm.detail.honorFlag === 'tenYoung')?_c('img',{staticClass:"party",attrs:{"src":_vm.honor10,"alt":""}}):_vm._e(),(_vm.detail.honorFlag === 'municipalStar')?_c('img',{staticClass:"party",attrs:{"src":_vm.honor11,"alt":""}}):_vm._e(),(_vm.detail.honorFlag === 'marketDevelopmentStar')?_c('img',{staticClass:"party",attrs:{"src":_vm.honor5,"alt":""}}):_vm._e(),(_vm.detail.honorFlag === 'innovationStar')?_c('img',{staticClass:"party",attrs:{"src":_vm.honor6,"alt":""}}):_vm._e(),(_vm.detail.honorFlag === 'projectManagementStar')?_c('img',{staticClass:"party",attrs:{"src":_vm.honor7,"alt":""}}):_vm._e(),(
      _vm.detail.talentIdentify &&
      _vm.detail.talentIdentify.reserveTalentFlag === 'technical'
    )?_c('img',{staticClass:"party",attrs:{"src":_vm.honor3,"alt":""}}):_vm._e(),(
      _vm.detail.talentIdentify &&
      _vm.detail.talentIdentify.reserveTalentFlag === 'management'
    )?_c('img',{staticClass:"party",attrs:{"src":_vm.honor4,"alt":""}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }