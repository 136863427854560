<template>
  <div id="chart1" style="height: 40vh"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
]);

export default {
  mounted() {
    var chartDom = document.getElementById("chart1");
    var myChart = echarts.init(chartDom);
    this.chart = myChart;

    var option;

    option = {
      grid: {
        top: "10%",
        bottom: "20%",
        left: "3%",
        right: "1%",
      },

      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },

      legend: {
        data: ["上级", "同事", "下级", "自评", "他评"],
        top: "bottom",
      },
      xAxis: [
        {
          type: "category",
          data: [
            "全局思维",
            "合作共赢",
            "为赢而战",
            "激励团队",
            "资源整合",
            "绩效管理",
            "驱动变化",
            "解决问题",
            "自我管理",
          ],
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          name: "上级",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: [13, 20, 30, 52, 26, 40, 30, 20, 10],
        },
        {
          name: "同事",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: [55, 10, 20, 82, 36, 30, 60, 80, 10],
        },
        {
          name: "下级",
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: [48, 90, 20, 12, 56, 30, 35, 40, 45],
        },

        {
          name: "自评",
          type: "line",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: [80, 90, 90, 50, 86, 90, 91, 95, 98],
        },
        {
          name: "他评",
          type: "line",
          tooltip: {
            valueFormatter: function (value) {
              return value;
            },
          },
          data: [80, 95, 88, 60, 50, 99, 98, 97, 96],
        },
      ],
    };

    option && myChart.setOption(option);
  },

  beforeDestroy() {
    this.chart?.dispose();
  },
};
</script>