<template>
  <div>
    <CardHeader :icon="icon9" title="近年荣誉" :showFold="false" />

    <div v-if="filteredList.length > 0">
      <div class="left item" v-for="(item, index) in filteredList" :key="index">
        <img class="icon" :src="honorImage" alt="" />
        <div class="name">{{ item.name }}</div>
        <div class="date">
          {{ item.issueDate ? item.issueDate : "" }}
        </div>
      </div>
    </div>
    <div v-else style="padding-top: 24px">
      <a-empty />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CardHeader from "../card-header.vue";

export default {
  components: {
    CardHeader,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    filteredList() {
      if (
        Array.isArray(this.detail.honorList) &&
        this.detail.honorList.length > 0
      ) {
        const list = [...this.detail.honorList];
        list.sort((x, y) => {
          if (x.issueDate && y.issueDate) {
            if (moment(x.issueDate).isBefore(moment(y.issueDate))) {
              return 1;
            } else if (moment(x.issueDate).isAfter(moment(y.issueDate))) {
              return -1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        });
        if (list.length > 4) {
          const [w, x, y, z] = list;
          return [w, x, y, z];
        } else {
          return list;
        }
      } else {
        return [];
      }
    },
  },

  data() {
    return {
      icon9: require("@/assets/employee/icon9.png"),
      honorImage: require("@/assets/employee/honor/honor12.png"),
    };
  },
};
</script>

<style lang="less" scoped>
.item {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 14px;
  margin-bottom: 14px;
  img {
    margin-right: 12px;
    height: 22px;
  }
  .name {
    font-weight: bold;
    font-size: 14px;
  }
  .date {
    margin-left: auto;
    color: #999;
  }
}
</style>