<template>
  <div class="title">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  display: inline-block;
  position: relative;
  top: -12px;
  left: -24px;
  background-color: #1890ff;
  color: #fff;
  padding: 4px 8px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -6px;
    width: 6px;
    bottom: 0;
    background-color: #99c5ee;
  }
  &::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 17px;
    width: 0;
    height: 0;
    border-bottom: 8px solid #1890ff;
    border-left: 8px solid transparent;
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    transform: rotate(45deg);
  }
}
</style>