<template>
    <div>
        <CardHeader :icon="icon1" title="个人标签" :showFold="false" />

        <div v-if="show" id="container" style="width: 100%;height: 200px;">
        </div>
        <div v-else style="padding-top: 24px">
            <a-empty />
        </div>
    </div>
</template>

<script>
import CardHeader from "../card-header.vue";
import * as echarts from 'echarts';
import 'echarts-wordcloud';
export default {
    components: {
        CardHeader,
    },

    props: {
        detail: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        filteredList() {
            if (
                Array.isArray(this.detail.tagList) &&
                this.detail.tagList.length > 0
            ) {
                const list = [...this.detail.tagList];
                return list;
            } else {
                return [];
            }
        },
    },

    data() {
        return {
            show: false,
            icon1: require("@/assets/employee/icon1.png"),
            honorImage: require("@/assets/employee/honor/honor13.png"),
        };
    },
    watch: {
        detail() {
            if (
                Array.isArray(this.detail.tagList) &&
                this.detail.tagList.length > 0
            ) {
                this.show = true;
                this.$nextTick(() => {
                    console.log(this.detail.tagList)
                    const data = this.detail.tagList.map(v=>{
                        return {
                            name:v.name,
                            value: Math.round(Math.random() * 40),
                        }
                    })
                    const dom = document.getElementById('container')
                    if (dom) {
                        const chart = echarts.init(dom)
                        chart.setOption({
                            series: [
                                {
                                    type: 'wordCloud',
                                    sizeRange: [15, 40],
                                    rotationRange: [0, 0],
                                    rotationStep: 45,
                                    gridSize: 8,
                                    shape: 'pentagon',
                                    width: '100%',
                                    height: '100%',
                                    data: data,
                                    textStyle: {
                                        fontFamily: 'sans-serif',
                                        fontWeight: 'bold',
                                        // Color can be a callback function or a color string
                                        color: function () {
                                            // Random color
                                            return 'rgb(' + [
                                                Math.round(Math.random() * 160),
                                                Math.round(Math.random() * 160),
                                                Math.round(Math.random() * 160)
                                            ].join(',') + ')';
                                        }
                                    },
                                    emphasis: {
                                        focus: 'self',

                                        textStyle: {
                                            textShadowBlur: 10,
                                            textShadowColor: '#333'
                                        }
                                    },
                                }
                            ]
                        })
                    }
                })
            }
        },
    },


};
</script>

<style lang="less" scoped>
.item {
    margin-bottom: 14px;

    img {
        width: 12px;
        margin-right: 4px;
    }

    .date {
        margin-left: auto;
        color: #999;
    }

    .desc {
        margin-top: 8px;
        padding-left: 16px;
    }

    .name {
        font-weight: bold;
        font-size: 14px;
    }

    .money {
        margin-left: auto;
        color: #1890ff;
        font-weight: bold;
    }
}
</style>