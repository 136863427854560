<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.contractList) ? detail.contractList : []
      "
      :pagination="false"
    >
      <a-table-column title="合同文件" align="center">
          <template slot-scope="text">
            {{ text.file?text.file.name:'--' }}
          </template>
        </a-table-column>
      <a-table-column title="合同开始日期" data-index="startDate" />
      <a-table-column title="合同结束日期" data-index="endDate" />
      <a-table-column title="合同类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="talentMap.employeeContractType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column title="状态" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.employeeContractStatus"
            :dictValue="text.status"
            iconType="badge"
          />
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>