<template>
  <div>
    <Pane :title="detail.name" :showRemarks="false" :bottom="0">
      <a-tabs
        v-model="active"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane key="2" tab="员工简历"> </a-tab-pane>
        <a-tab-pane key="1" tab="员工看板"> </a-tab-pane>
        
      </a-tabs>
    </Pane>

    <a-card class="container">
      <Avatar :detail="detail" />
      <Board :detail="detail" v-if="active === '1'" />
      <Resume :detail="detail" v-if="active === '2'" />
    </a-card>
  </div>
</template>

<script>
import Avatar from "./components/avatar.vue";
import Resume from "./components/resume/index.vue";
import Board from "./components/board/index.vue";
import { fetchDetail } from "@/api/human-resources/employee";
export default {
  components: {
    Avatar,
    Board,
    Resume,
  },

  data() {
    return {
      active: "2",
      id: null,
      detail: {},
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      fetchDetail({
        id,
      }).then((res) => {
        if (res) {
          this.detail = res;
        }
      });
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px;
}
</style>
