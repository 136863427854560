<template>
  <div>
    <div class="left header">
      <div class="title">工作经历</div>
    </div>

    <a-table
      bordered
      :data-source="Array.isArray(detail.hisWorkList) ? detail.hisWorkList : []"
      :pagination="false"
    >
      <a-table-column title="公司">
        <template slot-scope="text">
          {{ text.company }}
        </template>
      </a-table-column>
      <a-table-column title="部门">
        <template slot-scope="text">
          {{ text.dept }}
        </template>
      </a-table-column>

      <a-table-column title="职务">
        <template slot-scope="text">
          {{ text.position }}
        </template>
      </a-table-column>

      <a-table-column title="开始日期" align="center">
        <template slot-scope="text">
          {{ text.timeFrom }}
        </template>
      </a-table-column>
      <a-table-column title="结束日期" align="center">
        <template slot-scope="text">
          {{ text.timeTo }}
        </template>
      </a-table-column>
    </a-table>

    <div style="padding-top: 12px"></div>

    <div class="left header">
      <div class="title">项目经历</div>
    </div>

    <a-table
      bordered
      :data-source="
        Array.isArray(detail.hisProjectList) ? detail.hisProjectList : []
      "
      :pagination="false"
    >
      <a-table-column title="项目名称">
        <template slot-scope="text">
          {{ text.name }}
        </template>
      </a-table-column>

      <a-table-column title="项目金额(元)">
        <template slot-scope="text">
          <span class="money-color">
            {{ text.amount }}
          </span>
        </template>
      </a-table-column>

      <a-table-column title="项目级别">
        <template slot-scope="text">
          <DataDictFinder dictType="project-type" :dictValue="text.level" />
        </template>
      </a-table-column>

      <a-table-column title="担任角色">
        <template slot-scope="text">
          <DataDictFinder dictType="project-role" :dictValue="text.role" />
        </template>
      </a-table-column>

      <a-table-column title="开始日期" align="center">
        <template slot-scope="text">
          {{ text.timeFrom }}
        </template>
      </a-table-column>

      <a-table-column title="结束日期" align="center">
        <template slot-scope="text">
          {{ text.timeTo }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding-bottom: 12px;

  .title {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>