<template>
  <div>
    <a-row>
      <a-col :span="8">
        <Info :detail="detail" />
      </a-col>
      <a-col :span="8">
        <Performance :detail="detail" />
      </a-col>
      <a-col :span="8">
        <Honor :detail="detail" />
      </a-col>
    </a-row>
    <Padding />

    <a-row>
      <a-col :span="12">
        <Position :detail="detail" />
      </a-col>
      <a-col :span="12">
        <Job :detail="detail" />
      </a-col>
      <a-col :span="12">
        <Tag :detail="detail" />
      </a-col>
      <a-col :span="12">
        <Project :detail="detail" />
      </a-col>
    </a-row>
    <Padding />

    <CardHeader :icon="icon2" title="360评价" :showFold="false" />

    <Chart1 />

    <CardHeader :icon="icon4" title="管理个性" :showFold="false" />
    <Chart2 />
    <Padding />

    <CardHeader :icon="icon1" title="综合分析" :showFold="false" />

    <div class="left" style="margin-bottom: 24px">
      <Radar
        v-if="detail.talentIdentify && detail.talentIdentify.comprehensive"
        id="radar1"
        :list="list1"
      />
      <Radar
        v-if="detail.talentIdentify && detail.talentIdentify.abilitySkill"
        id="radar2"
        :list="list2"
      />
    </div>

    <CardHeader :icon="icon4" title="职业发展" :showFold="false" />
    <List :detail="detail" />
  </div>
</template>

<script>
import CardHeader from "../card-header.vue";
import Info from "./info.vue";
import Performance from "./performance.vue";
import Honor from "./honor.vue";
import Position from "./position.vue";
import Job from "./job.vue";
import Tag from "./tag.vue";
import Project from "./project.vue";
import Radar from "./radar.vue";
import Chart1 from "./chart1.vue";
import Chart2 from "./chart2.vue";
import List from "./list.vue";
export default {
  components: {
    CardHeader,
    Info,
    Performance,
    Honor,
    Position,
    Tag,
    Project,
    Job,
    Radar,
    Chart1,
    Chart2,
    List,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    list1() {
      const talentIdentify = this.detail.talentIdentify || {};
      return [
        { name: "人才发展积分", max: 100, value: talentIdentify.scoreDevelop },
        { name: "综合能力", max: 100, value: talentIdentify.comprehensive },
        { name: "企业文化匹配度", max: 100, value: talentIdentify.culture },
        { name: "360得分", max: 100, value: talentIdentify.score360 },
        { name: "第三方测评", max: 100, value: talentIdentify.evaluation3rd },
      ];
    },
    list2() {
      const talentIdentify = this.detail.talentIdentify || {};
      return [
        { name: "管理能力", max: 100, value: talentIdentify.abilityManage },
        { name: "技术能力", max: 100, value: talentIdentify.abilitySkill },
        { name: "销售能力", max: 100, value: talentIdentify.abilitySale },
        { name: "学习能力", max: 100, value: talentIdentify.abilityLearn },
        {
          name: "沟通能力",
          max: 100,
          value: talentIdentify.abilityCommunicate,
        },
      ];
    },
  },
  data() {
    return {
      icon1: require("@/assets/employee/board1.png"),
      icon2: require("@/assets/employee/board2.png"),
      icon3: require("@/assets/employee/board3.png"),
      icon4: require("@/assets/employee/board4.png"),
    };
  },
};
</script>