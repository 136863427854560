<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="
        Array.isArray(detail.credentialList) ? detail.credentialList : []
      "
      :pagination="false"
    >
      <a-table-column title="证件类型">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.credentialType"
            :dictValue="text.type"
          />
        </template>
      </a-table-column>
      <a-table-column title="证件编号">
        <template slot-scope="text">
          {{ text.sn }}
        </template>
      </a-table-column>
      <a-table-column title="证件号码">
        <template slot-scope="text">
          {{ text.number }}
        </template>
      </a-table-column>
      <a-table-column title="到期日期">
        <template slot-scope="text">
          {{ text.expireDate }}
        </template>
      </a-table-column>
      <a-table-column title="发证机关">
        <template slot-scope="text">
          {{ text.issueUnit }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>