<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="Array.isArray(detail.changeList) ? detail.changeList : []"
      :pagination="false"
    >
      <a-table-column title="异动类型">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.changeType"
            :dictValue="text.type"
          />
        </template>
      </a-table-column>
      <a-table-column title="拟调出部门">
        <template slot-scope="text">
          {{ renderDept(text.deptFrom) }}
        </template>
      </a-table-column>

      <a-table-column title="拟调入部门">
        <template slot-scope="text">
          {{ renderDept(text.deptTo) }}
        </template>
      </a-table-column>

      <!-- <a-table-column title="拟调整岗位">
        <template slot-scope="text">
          {{ text.post }}
        </template>
      </a-table-column>
      <a-table-column title="拟调整职级">
        <template slot-scope="text">
          {{ text.rank }}
        </template>
      </a-table-column>

      <a-table-column title="调整生效日期">
        <template slot-scope="text">
          {{ text.effectiveTime }}
        </template>
      </a-table-column> -->
      <a-table-column title="异动日期">
        <template slot-scope="text">
          {{ text.changeTime }}
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },

    organizationList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    renderDept(id) {
      const item = this.organizationList.find((item) => item.id === id);
      return item ? item.uniqueName : "--";
    },
  },
};
</script>