<template>
  <div class="left">
    <img
      class="party"
      v-if="detail.partyMember === 'member'"
      :src="party1"
      alt=""
    />
    <img
      class="party"
      v-if="detail.partyMember === 'modelMember'"
      :src="party2"
      alt=""
    />
    <img
      class="party"
      v-if="detail.partyMember === 'starModelMember'"
      :src="party3"
      alt=""
    />

    <img
      class="party"
      :src="honor9"
      alt=""
      v-if="detail.honorFlag === 'excellentStaff'"
    />
    <img
      class="party"
      :src="honor10"
      alt=""
      v-if="detail.honorFlag === 'tenYoung'"
    />

    <img
      class="party"
      :src="honor11"
      alt=""
      v-if="detail.honorFlag === 'municipalStar'"
    />

    <img
      class="party"
      :src="honor5"
      alt=""
      v-if="detail.honorFlag === 'marketDevelopmentStar'"
    />
    <img
      class="party"
      :src="honor6"
      alt=""
      v-if="detail.honorFlag === 'innovationStar'"
    />
    <img
      class="party"
      :src="honor7"
      alt=""
      v-if="detail.honorFlag === 'projectManagementStar'"
    />

    <img
      class="party"
      :src="honor3"
      alt=""
      v-if="
        detail.talentIdentify &&
        detail.talentIdentify.reserveTalentFlag === 'technical'
      "
    />
    <img
      class="party"
      :src="honor4"
      alt=""
      v-if="
        detail.talentIdentify &&
        detail.talentIdentify.reserveTalentFlag === 'management'
      "
    />
  </div>
</template>


<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      party1: require("@/assets/employee/honor/honor1.png"),
      party2: require("@/assets/employee/honor/honor2.png"),
      party3: require("@/assets/employee/honor/honor8.png"),

      honor9: require("@/assets/employee/honor/honor9.png"),
      honor10: require("@/assets/employee/honor/honor10.png"),
      honor11: require("@/assets/employee/honor/honor11.png"),
      honor5: require("@/assets/employee/honor/honor5.png"),
      honor6: require("@/assets/employee/honor/honor6.png"),
      honor7: require("@/assets/employee/honor/honor7.png"),

      honor3: require("@/assets/employee/honor/honor3.png"),
      honor4: require("@/assets/employee/honor/honor4.png"),
    };
  },
};
</script>

<style lang="less" scoped>
.party {
  height: 16px;
  margin-left: 12px;
}
</style>