<template>
  <div style="padding-bottom: 12px">
    <a-table
      bordered
      :data-source="Array.isArray(detail.honorList) ? detail.honorList : []"
      :pagination="false"
    >
      <a-table-column title="荣誉类型">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.honorLogo"
            :dictValue="text.type"
          />
        </template>
      </a-table-column>
      <a-table-column title="荣誉等级">
        <template slot-scope="text">
          <DataDictFinder
            dictType="talentMap.honorLevel"
            :dictValue="text.rank"
          />

          <DataDictFinder
            dictType="talentMap.externalHonorLevel"
            :dictValue="text.rank"
          />
        </template>
      </a-table-column>
      <a-table-column title="所获荣誉">
        <template slot-scope="text">
          {{ text.name }}
        </template>
      </a-table-column>

      <a-table-column title="授予日期">
        <template slot-scope="text">
          {{ text.issueDate }}
        </template>
      </a-table-column>
      <a-table-column title="授予机构">
        <template slot-scope="text">
          {{ text.issueUnit }}
        </template>
      </a-table-column>

      <a-table-column title="附件" align="center">
        <template slot-scope="text">
          <a v-if="text.attachment" target="_blank" :href="text.attachment"
            >预览</a
          >
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>